import React from "react";

const TicketReferences = () => {
  return (
    <div style={{ display: "flex", alignItems: "end", gap: "16px" }}>
      {/* Referencia: Ticket Abierto */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            display: "inline-block",
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "#28a745", // Verde
            marginRight: "8px",
          }}
        ></span>
        <span>Ticket Abierto</span>
      </div>

      {/* Referencia: Ticket Cerrado */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            display: "inline-block",
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "#6c757d", // Gris
            marginRight: "8px",
          }}
        ></span>
        <span>Ticket Cerrado</span>
      </div>

      {/* Referencia: Ticket Cancelado */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            display: "inline-block",
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "#dc3545", // Rojo
            marginRight: "8px",
          }}
        ></span>
        <span>Ticket Cancelado</span>
      </div>
    </div>
  );
};

export default TicketReferences;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Card, CardBody, CardImg, CardTitle, CardFooter } from "reactstrap";
import styles from "./styles.module.css";
import moment from "moment";
import "moment/locale/es";

function formatLicensePlate(plate) {
  // Expresión regular para separar letras y números
  return plate.replace(
    /([A-Z]+)(\d+)([A-Z]*)/,
    (match, letters1, numbers, letters2) => {
      const parts = [letters1, numbers, letters2].filter(Boolean); // Elimina partes vacías
      return parts.join(" "); // Une las partes con un espacio
    }
  );
}

 const TimeAgo = ({ date }) => {
   moment.locale("es");
   const timeAgo = moment(date).fromNow();
    const time = moment(date).format("DD/MM HH:mm");
   return <span>{time} - {timeAgo}</span>;
};
 

const ItemAnpr = (props) => {
    const item = props.ticket;
    
     
    
    
  return (
    <Card className={`${styles.card} mb-4 shadow-sm`}>
      <div className={styles["image-container"]}>
        <CardImg
          top
          src={`data:image/png;base64,${item.picture}`}
          alt={`Imagen del vehículo con matrícula ${item.plate}`}
          className={styles["card-img"]}
        />
        {/* Indicador flotante */}
        <div
          className={`${styles["status-indicator"]} ${
            item.canceled === 1
              ? styles["status-canceled"]
              : item.closed === 0
              ? styles["status-open"]
              : styles["status-closed"]
          }`}
        ></div>
      </div>
      <CardBody className={`${styles["card-body"]}`}>
        <CardTitle tag="h3" className={styles["cardTitle"]}>
          <strong>{formatLicensePlate(item.plate)}</strong>
        </CardTitle>
        <div className={styles["card-category"]}>{item.category_name}</div>
        <div className={styles["card-car-details"]}>{item.car_detail}</div>
        {item.canceled == 1 && (
          <div className={"text-center " + styles["card-canceled-text"]}>
            Cancelado
          </div>
        )}
        {/* {JSON.stringify(item)} */}
      </CardBody>
      {/* FOOTER */}

      {/* Footer del Card */}
      <CardFooter className={styles["card-footer"]}>
        <TimeAgo date={item.init_date} />
        <div>
          <small>Abierto por {item.fullName.toUpperCase()}</small>
        </div>
      </CardFooter>
    </Card>
  );
};

export default ItemAnpr;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Badge, Spinner, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { firestore_licenses } from "../../Firebase/firebaseConfig";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from 'moment';
import ConfirmActionModal from './ConfirmActionModal'; // Importa el modal de confirmación
import { Link } from "react-router-dom";

const ServerUrl = process.env.REACT_APP_SERVER_URL;

const Order = () => {
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [user_order, setUserOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({ isOpen: false, actionName: '', action: null });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const orderRef = doc(firestore_licenses, "orders", id);
                const orderSnap = await getDoc(orderRef);

                if (orderSnap.exists()) {
                    setOrder(orderSnap.data());
                } else {
                    console.log("No such document!");
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching order:", error);
                setLoading(false);
            }
        };

        fetchOrder();
    }, [id]);


    useEffect(() => {
        if (order) {
            const fetchUser = async () => {
                try {
                    const userRef = doc(firestore_licenses, "users", order.userId);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        setUserOrder(userSnap.data());
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.error("Error fetching user:", error);
                }
            };

            fetchUser();
        }
        else {
            setUserOrder(null);
        }


    }, [order]);

    const handleApprove = async () => {
        const apiKey = process.env.REACT_APP_APIKEY_LICENCE;
        try {
            setLoading(true);
            const response = await fetch(`${ServerUrl}/approveOrder`, {
                method: 'POST',
                cors: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                },
                body: JSON.stringify({ orderId: id }),
            });

            setLoading(false);
            if (response.status === 201) {
                const orderRef = doc(firestore_licenses, "orders", id);
                await updateDoc(orderRef, { status: 'approved' });
                setOrder(prev => ({ ...prev, status: 'approved' }));
            } else {
                alert('Error en la solicitud al servidor');
            }
        } catch (error) {
            alert('Error en la solicitud al servidor', error);
        }
    };

    const handleCancel = async () => {
        try {
            const orderRef = doc(firestore_licenses, "orders", id);
            await updateDoc(orderRef, { status: 'cancelled' });
            setOrder(prev => ({ ...prev, status: 'cancelled' }));
        } catch (error) {
            console.error("Error cancelling order:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const orderRef = doc(firestore_licenses, "orders", id);
            await deleteDoc(orderRef);
            navigate('/orders');
        } catch (error) {
            console.error("Error deleting order:", error);
        }
    };

    const toggleModal = (actionName, action) => {
        setModal({ isOpen: !modal.isOpen, actionName, action });
    };

    if (loading) {
        return <Spinner color="primary" />;
    }

    if (!order) {
        return <p>No se encontró la orden.</p>;
    }

    const copyClipboard = (text) => {
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = text;
        tempTextArea.style.position = 'fixed';
        tempTextArea.style.top = '0';
        tempTextArea.style.left = '0';
        tempTextArea.style.opacity = '0';
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);
        alert('Licencia copiada al portapapeles');
    }

    return (
      <>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Órdenes"
              breadcrumbItem="Detalles de la Orden"
            />
            <Row>
              <Col lg={6} md={6} xs={12}>
                <Card className="mt-0">
                  <CardBody style={{ fontSize: 18 }}>
                    <CardTitle>
                      <Row className={"align-items-center"}>
                        <Col>
                          <h1>Orden #{id}</h1>
                        </Col>
                        <Col className="text-end">
                          <h2>
                            {order.status === "approved" ? (
                              <Badge color="success">Aprobado</Badge>
                            ) : order.status === "pending" ? (
                              <Badge color="warning">Pendiente</Badge>
                            ) : (
                              <Badge color="danger">Cancelado</Badge>
                            )}
                          </h2>
                        </Col>
                      </Row>
                    </CardTitle>
                    <hr></hr>
                    <p>
                      <strong>Fecha de Creación:</strong>{" "}
                      {order.createdAt
                        ? order.createdAt.toDate
                          ? moment(order.createdAt.toDate()).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : moment(order.createdAt).format("DD/MM/YYYY HH:mm")
                        : "-"}
                    </p>
                    <p>
                      <strong>Usuario:</strong> {order.user}
                    </p>
                    <p>
                      <strong>Equipo:</strong>{" "}
                      <Link to={`/dashboard/${order.keyCode}`}>
                        {order.keyCode}
                      </Link>
                    </p>
                    <p>
                      <strong>Método de Pago:</strong> {order.paymentMethod}
                    </p>
                    <p>
                      <strong>Total:</strong> {order.currency}{" "}
                      {new Intl.NumberFormat("es-AR", {
                        minimumFractionDigits: 0,
                      }).format(order.total)}
                    </p>
                    <p>
                      <strong>Límite:</strong> {order?.limit}
                    </p>
                    {order.license && (
                      <p>
                        <strong>Factura Electrónica:</strong>{" "}
                        {order.license?.invoices ? "Sí" : "No"}
                      </p>
                    )}
                    {order.license?.code && (
                      <p>
                        <strong>Licencia:</strong> {order.license?.code}
                      </p>
                    )}
                    <hr></hr>
                    <Row className={"mt-3 pt-3"}>
                      <Col>
                        {order.status !== "approved" && (
                          <Button
                            color="success"
                            onClick={() =>
                              toggleModal("Aprobar", handleApprove)
                            }
                            className="me-3"
                          >
                            Aprobar
                          </Button>
                        )}
                        {order.status !== "cancelled" && (
                          <Button
                            color="danger"
                            onClick={() =>
                              toggleModal("Cancelar", handleCancel)
                            }
                            className="me-3"
                          >
                            Cancelar
                          </Button>
                        )}
                        {order.license?.code && (
                          <Button
                            color="primary"
                            onClick={() => copyClipboard(order.license.code)}
                            className="me-3"
                          >
                            Copiar Licencia
                          </Button>
                        )}
                      </Col>
                      <Col className="text-end">
                        <Button
                          color="danger"
                          onClick={() => toggleModal("Eliminar", handleDelete)}
                        >
                          Eliminar
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <Card className="mt-0">
                      <CardBody style={{ fontSize: 18 }}>
                        <CardTitle>
                          <h4>Datos del Cliente</h4>
                        </CardTitle>
                        {/* {"country":"Argentina","whatsapp":"542235977904","firstName":"Diego","email":"dibarral@gmail.com","billingData":{"ivaCondition":"IVA Sujeto Exento","cuit":"30716122197"},"lastName":"Barral","city":"Mar del Plata"} */}
                        <p className="mt-4">
                          <strong>Nombre:</strong> {user_order?.firstName}{" "}
                          {user_order?.lastName}
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          <a href={`mailto:${user_order?.email}`}>
                            {user_order?.email}
                          </a>
                        </p>
                        <p>
                          <strong>Whatsapp:</strong>{" "}
                          <a
                            href={`https://wa.me/${user_order?.whatsapp}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {user_order?.whatsapp}
                          </a>
                        </p>
                        <p>
                          <strong>País:</strong> {user_order?.country}
                        </p>
                        {/* <p><strong>Provincia:</strong> {user_order?.province}</p> */}
                        <p>
                          <strong>Localidad:</strong> {user_order?.city}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Card className="mt-0">
                      <CardBody style={{ fontSize: 18 }}>
                        <CardTitle>
                          <h4 className="mb-4">Datos de Facturación</h4>
                        </CardTitle>
                        {user_order?.billingData &&
                          Object.entries(user_order.billingData).map(
                            ([key, value], index) => (
                              <p key={index}>
                                <strong>{key}:</strong> {value}
                              </p>
                            )
                          )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Modal de Confirmación */}
        <ConfirmActionModal
          isOpen={modal.isOpen}
          toggle={() => toggleModal("", null)}
          action={modal.action}
          actionName={modal.actionName}
          confirmDelete={modal.actionName === "Eliminar"}
          orderId={id}
        />
      </>
    );
}

export default Order;
